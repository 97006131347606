// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import AccountMod from '~/packages/spa-ec/src/components/Account/AccountMod.theme-minespresso.js';
import BasicHeader from '~/packages/spa-ec/src/components/Header/BasicHeader.theme-minespresso.js';
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-minespresso.js';
import CartTable from '~/packages/spa-ec/src/displayComponents/CartTable/CartTable.theme-minespresso.js';
import ComponentsContainer from '~/packages/spa-ec/src/displayComponents/Cms/ComponentsContainer.theme-minespresso.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-minespresso.js';
import ContentPage from '~/packages/spa-ec/src/routes/ContentPage/ContentPage.theme-minespresso.js';
import GDPRPopupMod from '~/packages/spa-ec/src/components/GDPRPopup/GDPRPopupMod.theme-minespresso.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-minespresso.js';
import OrderHistory from '~/packages/spa-ec/src/components/OrderHistory/OrderHistory.theme-minespresso.js';
import PageTitle from '~/packages/spa-ec/src/components/StaticPageComponents/PageTitle.theme-minespresso.js';
import PasswordRequest from '~/packages/spa-ec/src/components/PasswordRequest/PasswordRequest.theme-minespresso.js';
import ProductRow from '~/packages/spa-ec/src/components/Account/Subscription/ProductRow.theme-minespresso.js';
import ProductUpsellCarouselMod from '~/packages/spa-ec/src/components/ProductUpsell/ProductUpsellCarouselMod.theme-minespresso.js';
import ProfileMod from '~/packages/spa-ec/src/components/Profile/ProfileMod.theme-minespresso.js';
import Radio from '~/packages/ui-elem/src/Radio.theme-minespresso.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-minespresso.js';
import StockStatus from '~/packages/spa-ec/src/components/StockStatus/StockStatus.theme-minespresso.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-minespresso.js';

const overrides = {
  AccountMod,
  BasicHeader,
  CartMod,
  CartTable,
  ComponentsContainer,
  ContactUsPage,
  ContentPage,
  GDPRPopupMod,
  OrderConfirmation,
  OrderHistory,
  PageTitle,
  PasswordRequest,
  ProductRow,
  ProductUpsellCarouselMod,
  ProfileMod,
  Radio,
  SiteLogo,
  StockStatus,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
