import PropTypes from 'prop-types';
import React from 'react';
import logger from 'score/logSvc';
import { Metadata } from 'deco/Head';
import ContentSlot from 'eco/ContentSlot/ContentSlotMod.jsx';
import Breadcrumbs from '@spa-ec/components/Breadcrumbs/Breadcrumbs';
import { getThemeFactory } from '@ic-theme';
import * as cls from './ContentPage.styles';
import classNames from 'classnames';
import styled from 'styled-components';
import PageTitle from 'eco/StaticPageComponents/PageTitleMod';
import loadWithSSRFallback from 'ui/Loader';
import CustomerFeedback from '@spa-ec/components/CustomerFeedback/CustomerFeedback';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import PersonalizedCockpit from '@spa-ec/components/PersonalizedCockpit/PersonalizedCockpit';

const SSRCustomerFeedback = loadWithSSRFallback(
  () => import('@spa-ec/components/CustomerFeedback/CustomerFeedback'),
  'CustomerFeedback',
  {
    hydrate: 'always',
    force: true,
  }
);

const HomepageCategorySection = loadWithSSRFallback(
  () => import('@spa-ec/components/HomepageCategorySection/HomepageCategorySection'),
  'HomepageCategorySection',
  {
    hydrate: 'always',
    force: true,
  }
);

const name = 'ContentPage';
const log = logger.getLogger(name);
const cmsTicketId = sessionStorage.getItem('cmsTicketId');

const thFactory = getThemeFactory('ContentPage', {
  conf: {
    showSideNavigationOnStaticPages: false,
    showTitleOnContentPageDesktop: false,
    showTitleOnContentPageMobile: false,
    hideTitleForPages: [{}],
    showHomepageCategorySection: false,
  },
  sideContentWidth: '25%',
  sideContentPadding: 'px-8',
});

const Slots = (p) => (
  <>
    <ContentSlot
      iid={p.pageId + '---TopBannerArea'}
      key={p.pageId + '---TopBannerArea'}
      SSRKey={p.pageId + '---TopBannerArea'}
      position={'TopBannerArea'}
      pageId={p.pageId}
    />
    <ContentSlot
      iid={p.pageId + '---Section1'}
      key={p.pageId + '---Section1'}
      SSRKey={p.pageId + '---Section1'}
      position={'Section1'}
      pageId={p.pageId}
    />
    {p.template === 'CUSTOMER_FEEDBACK' ? (
      <>
        <ContentSlot
          iid={p.pageId + '---TopContent'}
          key={p.pageId + '---TopContent'}
          SSRKey={p.pageId + '---TopContent'}
          position={'TopContent'}
          pageId={p.pageId}
        />
        <CustomerFeedback isCustomerFeedbackPage={true} />
        <ContentSlot
          iid={p.pageId + '---BottomContent'}
          key={p.pageId + '---BottomContent'}
          SSRKey={p.pageId + '---BottomContent'}
          position={'BottomContent'}
          pageId={p.pageId}
        />
      </>
    ) : null}
  </>
);

const StyledStaticPage = styled.div`
  &&& {
    @media (max-width: 370px) {
      .ICIframe {
        zoom: 0.85;
        transform: scale(0.85);
        transform-origin: 0 0;
        -moz-transform: scale(0.85);
        -moz-transform-origin: 0 0;
      }
    }
    .content {
      a {
        color: ${(props) => (props.theme.linkColor ? props.theme.linkColor : props.theme.col.primary.fg)};
        text-decoration: none;
        :hover {
          color: ${(props) => (props.theme.linkColorHover ? props.theme.linkColorHover : props.theme.col.primary.peer)};
          text-decoration-color: ${(props) => props.theme.col.primary.peer};
          text-decoration: underline;
        }
      }
    }
    .side-content {
      :empty {
        display: none !important;
      }
      @media (min-width: 900px) {
        width: ${(props) => props.sideContentWidth};
      }
    }
  }
`;

export default class ContentPage extends React.Component {
  /**
   * propTypes
   */
  static get propTypes() {
    return {
      pageId: PropTypes.string,
      metadata: PropTypes.object,
    };
  }

  /**
   * Function called before mounting component. Do initialisations here that should not happen every render, potentially
   * serveral times per second.
   */
  componentDidMount() {}

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const theme = thFactory();
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    return this.props.template === 'STATIC_PAGE' || this.props.template === 'CUSTOMER_FEEDBACK' ? (
      <StyledStaticPage theme={theme} data-ssr-key={this.props.pageId} sideContentWidth={theme.sideContentWidth}>
        <Metadata metadata={this.props.metadata} appendSiteName={true} />
        {theme.conf.showBreadCrumbs ? <Breadcrumbs typeOfPage={'content'} pageId={this.props.pageId} /> : null}

        <div className={'flex content-bkg'}>
          {theme.conf.showSideNavigationOnStaticPages ? (
            <div
              className={classNames(
                'e2e-side-nav md:w-1/4 lg:w-1/5 hidden md:block mt-4 content side-content',
                theme.sideContentPadding
              )}
            >
              <ContentSlot
                iid={this.props.pageId + '---Section2A'}
                key={this.props.pageId + '---Section2A'}
                SSRKey={this.props.pageId + '---Section2A'}
                position={'Section2A'}
                pageId={this.props.pageId}
              />
            </div>
          ) : null}
          <div className={theme.conf.showSideNavigationOnStaticPages ? 'w-full md:w-3/4 lg:w-4/5 flex-1' : 'w-full'}>
            <PageTitle title={this.props.metadata.title} isloggedIn={this.props.isLoggedIn} />
            {theme.conf.paddingInContentBlock ? (
              <div className={'p-4 content-bkg content'}>
                <Slots {...this.props} />
              </div>
            ) : (
              <Slots {...this.props} />
            )}
          </div>
        </div>
      </StyledStaticPage>
    ) : (
      <div data-ssr-key={this.props.pageId} className={'min-h-screen'}>
        {this.props.showBreadCrumbsOnPage === true && theme.conf.showBreadCrumbs && this.props.pageId.indexOf('homepage') < 0 ? (
          <Breadcrumbs typeOfPage={'content'} pageId={this.props.pageId} />
        ) : null}
        <div className={'e2e-content-page content-page'} key={'content-page'}>
          <Metadata metadata={this.props.metadata} appendSiteName={true} />
          <ContentSlot
            iid={this.props.pageId + '---CSAdmin'}
            key={this.props.pageId + '---CSAdmin'}
            SSRKey={this.props.pageId + '---CSAdmin'}
            position={'CSAdmin'}
            pageId={this.props.pageId}
            removeCaching={this.props.isLoggedIn === true}
          />
          {this.props.pageId.indexOf('homepage') > 0 || this.props.pageId === 'homepageForMobile' ? (
            <PersonalizedCockpit key={'the-cockpit'} SSRKey={'the-cockpit'} className={'e2e-ver-cockpit'} />
          ) : null}
          {cmsTicketId !== null ? (
            <div className={classNames('md:mb-2 lg:mb-0 w-full lg:w-4/5', cls.billboard)}>
              <ContentSlot
                iid={this.props.pageId + '---CampaignBillboard'}
                key={this.props.pageId + '---CampaignBillboard'}
                SSRKey={this.props.pageId + '---CampaignBillboard'}
                position={'CampaignBillboard'}
                pageId={this.props.pageId}
                removeCaching={this.props.campaignPageId}
              />
            </div>
          ) : null}
          <div className={'flex flex-col lg:flex-row e2e-ver-billboard'} key={'content-page-div'}>
            <div className={classNames('flex-1 my-1', cls['find-model'])} key={'SFM_TopLeftArea'}>
              <ContentSlot
                iid={this.props.pageId + '---TopLeftArea'}
                key={this.props.pageId + '---TopLeftArea'}
                SSRKey={this.props.pageId + '---TopLeftArea'}
                position={'TopLeftArea'}
                pageId={this.props.pageId}
              />
            </div>
            <div className={classNames('md:mb-2 lg:mb-0 w-full lg:w-4/5', cls.billboard)}>
              <ContentSlot
                iid={this.props.pageId + '---Billboard'}
                key={this.props.pageId + '---Billboard'}
                SSRKey={this.props.pageId + '---Billboard'}
                position={'Billboard'}
                pageId={this.props.pageId}
                removeCaching={this.props.campaignPageId}
              />
            </div>

            <div className={classNames('flex-1 my-1', cls['find-model'])} key={'SFM_TopRightArea'}>
              <ContentSlot
                iid={this.props.pageId + '---TopRightArea'}
                key={this.props.pageId + '---TopRightArea'}
                SSRKey={this.props.pageId + '---TopRightArea'}
                position={'TopRightArea'}
                pageId={this.props.pageId}
              />
            </div>
          </div>
          <ContentSlot
            iid={this.props.pageId + '---Welcome'}
            key={this.props.pageId + '---Welcome'}
            SSRKey={this.props.pageId + '---Welcome'}
            position={'Welcome'}
            pageId={this.props.pageId}
          />
          <ContentSlot
            iid={this.props.pageId + '---Highlights'}
            key={this.props.pageId + '---Highlights'}
            SSRKey={this.props.pageId + '---Highlights'}
            position={'Highlights'}
            pageId={this.props.pageId}
          />
          <ContentSlot
            iid={this.props.pageId + '---Checklist'}
            key={this.props.pageId + '---Checklist'}
            SSRKey={this.props.pageId + '---Checklist'}
            position={'Checklist'}
            pageId={this.props.pageId}
          />
          <ContentSlot
            iid={this.props.pageId + '---PuffHeader'}
            key={this.props.pageId + '---PuffHeader'}
            SSRKey={this.props.pageId + '---PuffHeader'}
            position={'PuffHeader'}
            pageId={this.props.pageId}
          />
          {theme.conf.showHomepageCategorySection && this.props.pageId === 'homepageSPA' ? <HomepageCategorySection /> : null}
          <ContentSlot
            iid={this.props.pageId + '---Section1'}
            key={this.props.pageId + '---Section1'}
            SSRKey={this.props.pageId + '---Section1'}
            position={'Section1'}
            pageId={this.props.pageId}
          />
          <ContentSlot
            iid={this.props.pageId + '---Section2A'}
            key={this.props.pageId + '---Section2A'}
            SSRKey={this.props.pageId + '---Section2A'}
            position={'Section2A'}
            pageId={this.props.pageId}
          />
          {sessionConfig.enableOrderTestimonial ? (
            <div className="md:block">
              <SSRCustomerFeedback ssrKey={'CustomerFeedback'} displayCount={4} deviceDisplayCount={2} link={true} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
ContentPage.contextTypes = {
  store: PropTypes.object,
};
