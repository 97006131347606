const palette = {
    darkBrown: '#3b210a',
    minespressoBlack: '#23130d',
    ristrettoBlack: '#434343',
    espressoBrown: '#82553f',
    ekoGreen: '#5eaf76',
    ekoGreenShade: '#458a58',
    lungoBlue: '#3a5c8f',
    meDark: '#23130d',
    meBrown: '#3b210a',
    meLightBrown: '#725441',
    meLightBrownShade: '#5c3f2e',
    meCream: '#f5e7d9',
    meDarkCream: '#bfa184',
    meGrayCream: '#baa9a0',
    meGray: '#707070',
    lightGray: '#e1e1e1',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'minespresso',
    radius: '0',
    // OBS! palette names are not be used directly in common components! Will break...
    // In theme specific ones  is ok
    palette,
    col: {
        primary: {
            alt: 'white',
            fg: palette.ekoGreen,
            peer: palette.ekoGreenShade,
        },
        secondary: {
            fg: palette.meLightBrown,
            peer: palette.meLightBrownShade,
            alt: 'white',
        },
        tertiary: {
            fg: palette.meLightBrown,
            peer: palette.meLightBrownShade,
            alt: 'white',
        },
        panel: {
            alt: palette.meBrown,
            peer: palette.meDarkCream,
            fg: palette.meCream,
        },
        info: {
            alt: 'white',
            fg: '#6376ba',
            peer: '#5367aa',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: '#343434',
            fg: 'white',
            peer: palette.meDarkCream,
        },
        splash: {
            alt: 'white',
            fg: '#8585bf',
            peer: '#7e7eb5',
        },
        vars: {
            text: palette.meDark,
        },
    },
    font: {
        family: 'Roboto, sans-serif',
        header: {
            family: '"Open Sans", ​sans-serif',
        },
        special: {
            family: 'Times new roman',
        },
        size: {
            // x2: '1.8em',
            // x1: '20pt',
            // m: '1.15rem',
            // s1: '0.85em',
            // s2: '1.0em',
            x4: '32px',
            x3: '27.4px',
            x2: '24.4px',
            x1: '21.4px',
            m: '18.4px',
            s1: '16px',
            s2: '13px',
            s3: '10px',
        },
    },
    conf: {
        showBrand: false,
        showTitle: true,
        showPDPLink: true,
        paddingInContentBlock: false,
    },
};
export default theme;
