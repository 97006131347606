import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Icon, { Icons } from '@ui-elem/Icon/Icon';
import PropTypes from 'prop-types';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, mapService, setupMutations } from 'core/util/module-utils';
import logger from 'score/logSvc';
import net from 'score/networkSvc';
import browserSvc from 'score/browserSvc';
import { str } from 'seco/localeSvc';
import styled from 'styled-components';
import { getThemeFactory } from '@ic-theme';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const sessionConfig = getReducer(appReducerName).sessionConfig;

const th = getThemeFactory('PageTitle', {
  fg: 'white',
  showLogoutIcon: true,
  combineSections: false,
});
// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'account';

// Mudules data, this is the initial data for an instance
const initialState = {
  purchaseType: 'onetime',
};

const log = logger.getLogger(name);
const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));

  // Adding an extra getter that is not only a variable but some calculation based on the state
  return instance;
};

// ################# ACTIONS  #################
const actions = (dispatch, ownProps) => ({
  updPurchaseType: () =>
    dispatch({
      type: 'ACCOUNT_',
    }),
  logOut: () => {
    dispatch(async () => {
      net.get(sessionConfig.urlPrefix + '/logout').then(
        () => {
          const message = {
            id: 'logout.success',
            message: str('phrase.you_are_now_logged_out'),
            level: 'info',
          };
          browserSvc.sessionSet('logout_msg', JSON.stringify(message));
          location.href = sessionConfig.urlPrefix;
        },
        (error) => {
          log.warn('error');
          if (error.status === 200) {
            const message = {
              id: 'logout.success',
              message: str('phrase.you_are_now_logged_out'),
              level: 'info',
            };
            browserSvc.sessionSet('logout_msg', JSON.stringify(message));
          }
          location.href = sessionConfig.urlPrefix;
        }
      );
    });
  },
});

// ################# MUTATIONS  #################

const mutations = {};

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name, state: setupInitialState(initialState, conf), actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};

// ################# RENDER  #################

const StyledTitle = styled.div`
  &&& {
    position: relative;
    background: ${(p) => (p.theme.bg ? p.theme.bg : p.theme.col.primary.fg)};
    h1 {
      color: ${(p) => p.theme.fg};
      border: ${(p) => (p.theme.noBorder ? '0px' : 'inherit')};
    }
    button {
      position: absolute;
      top: ${(p) => p.theme.space / 2}rem;
      right: 0;
    }
    ${(p) =>
      p.theme.combineSections === true
        ? 'h1{border-bottom: 1px solid ' +
          p.theme.bottomBorderColor +
          '; width:98%; margin: 0 auto;padding-bottom:5px; @media (max-width: 580px){width:92%;}}}'
        : 'margin-bottom: 12px'};
  }
`;

class PageTitle extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string,
    };
  }
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const theme = th();
    return (
      <>
        {this.props.title ? (
          <>
            <StyledTitle className={'flex flex-row ic-me-page-title p-0 print:hidden'} theme={theme}>
              <h1 className="text-center m-0 ml-16 p-4 w-11/12">{this.props.title}</h1>
              {theme.showLogoutIcon && this.props.isloggedIn ? (
                <div className={'flex justify-end w-1/12'}>
                  <Icon
                    icon={Icons.SignOutAlt}
                    onClick={this.props.logOut}
                    size={32}
                    className={'e2e-ver-logout color-pale cursor-pointer p-4 ic-me-logout '}
                  />
                </div>
              ) : null}
            </StyledTitle>
            <div className="hidden print:block">
              <h1 className="text-center m-0 p-4">{this.props.title}</h1>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default withRouter(connect(getters, actions)(PageTitle));
