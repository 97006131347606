import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getThemeFactory } from '@ic-theme';
import FixedDeck from './Deck.jsx';

// Todo test nuka-carousel instead of react slick

const theme = getThemeFactory('Deck', {});

class Deck extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      /** The alignment of cards in the last row that is not full */
      children: PropTypes.node,
      min: PropTypes.number,
      mobileMinWidth: PropTypes.number,
      ipadMinWidth: PropTypes.number,
      oneRow: PropTypes.bool,
      noGutter: PropTypes.bool,
      forceFixedGrid: PropTypes.bool,
      /** The alignment of cards in the last row that is not full */
      align: PropTypes.string,
      autoPlay: PropTypes.bool,
      autoPlaySpeed: PropTypes.any,
    };
  }

  render() {
    const th = theme();

    const p = this.props;
    const GridDeck = styled.div`
      &&& {
        width: 100%;
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(${p.min}px, 1fr)); */
        grid-template-columns: repeat(auto-fill, minmax(${p.min}px, 1fr));
        @media (max-width: ${th?.size?.sm}px) {
          grid-template-columns: repeat(auto-fill, minmax(${p.mobileMinWidth}px, 1fr));
        }
        @media (min-width: 600px) and (max-width: ${th?.size?.md}px) {
          ${p.ipadMinWidth
            ? 'grid-template-columns: repeat(auto-fill, minmax(' + p.ipadMinWidth + 'px, 1fr))'
            : 'grid-template-columns: repeat(auto-fill, minmax(' + p.min + 'px, 1fr))'}
        }
        justify-content: center;
        ${th?.space &&
        `
          column-gap: ${p.noGutter ? 0 : `${th?.space}rem`};
        row-gap: ${p.noGutter ? 0 : `${th?.space}rem`};
        column-gap: ${p.halfGutter ? '' : `${th?.space / 2}rem`};
        row-gap: ${p.halfGutter ? '' : `${th?.space / 2}rem`};
        `}
      }
    `;

    if (this.props.oneRow) {
      return <FixedDeck {...this.props} />;
    }
    if (this.props.forceFixedGrid) {
      return <FixedDeck {...this.props} />;
    }

    return (
      <ThemeProvider theme={th}>
        <GridDeck>{this.props.children}</GridDeck>
      </ThemeProvider>
    );
  }
}
Deck.defaultProps = {
  min: 230,
  mobileMinWidth: 150,
  oneRow: false,
  align: 'center',
  noGutter: false,
  forceFixedGrid: false,
};

export default Deck;
