import React, { MouseEventHandler } from 'react'
import classnames from 'classnames'
import CardImage from './CardImage/CardImage'
import CardDescription from './CardDescription/CardDescription'
import CardStockStatus from './CardStockStatus/CardStockStatus'
import CardBracketPrice from './CardBracketPrice/CardBracketPrice'
import CardProductPrice from './CardProductPrice/CardProductPrice'
import CardButtons from './CardButtons/CardButtons'
import CardPopup from './CardPopup/CardPopup'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductOneBuyStatus } from '@spa-core/store/products/constants'
import { ColumnCardTheme, NAME, ColumnCartLayout } from './ColumnCard.theme'
import { getComponentTheme } from '@spa-core/theme/theme'

const theme: ColumnCardTheme = getComponentTheme<ColumnCardTheme>(NAME, {
    layout: ColumnCartLayout.SINGLE_COLUMN,
})

interface Props {
    width: number
    resolvedBackground: string
    slimProduct: SlimProduct
    price: string
    formattedDiscountedPrice: string
    hasDiscount: boolean
    subscriptionIsOpen: boolean
    subscriptionButtonHandler: MouseEventHandler
    buyButtonHandler: (currentlyAddingKey: string) => void
    oneBuyStatus: ProductOneBuyStatus
    showBuyPanel: boolean
    closeSubscribePopup: Function
    topLeftPromo?: React.ReactElement
    topRightPromo?: React.ReactElement
    searchTerm?: string
}

const ColumnCard = ({
    resolvedBackground,
    width,
    slimProduct,
    topLeftPromo,
    topRightPromo,
    searchTerm,
    price,
    formattedDiscountedPrice,
    hasDiscount,
    subscriptionIsOpen,
    subscriptionButtonHandler,
    buyButtonHandler,
    oneBuyStatus,
    showBuyPanel,
    closeSubscribePopup,
}: Props): React.ReactNode => (
    <div
        className={classnames(resolvedBackground, 'flex flex-col justify-between py-1 px-2 md:p-2 e2e-ver-SlimColumnCard')}
        style={{ width }}
    >
        <CardImage searchTerm={searchTerm} slimProduct={slimProduct} topLeftPromo={topLeftPromo} topRightPromo={topRightPromo} />
        <CardDescription slimProduct={slimProduct} searchTerm={searchTerm} />

        <div className="text-s1" key="card-buy-section">
            {theme.layout === ColumnCartLayout.SINGLE_COLUMN ? (
                <>
                    {/* Product bracket price section, only for tobacco products  */}
                    {slimProduct.isSnusProduct ? <CardBracketPrice slimProduct={slimProduct} /> : null}

                    <div>
                        <CardProductPrice
                            price={price}
                            formattedDiscountedPrice={formattedDiscountedPrice}
                            hasDiscount={hasDiscount}
                        />
                        <div className="md:flex md:justify-around">
                            <CardButtons
                                slimProduct={slimProduct}
                                subscriptionIsOpen={subscriptionIsOpen}
                                subscriptionButtonHandler={subscriptionButtonHandler}
                                buyButtonHandler={buyButtonHandler}
                                oneBuyStatus={oneBuyStatus}
                            />
                        </div>
                        <CardStockStatus stockStatus={slimProduct.stockStatus} />
                    </div>
                </>
            ) : (
                <div className={'flex flex-row justify-between w-full'}>
                    <div className={'mt-2 w-1/2'}>
                        <CardProductPrice
                            price={price}
                            formattedDiscountedPrice={formattedDiscountedPrice}
                            hasDiscount={hasDiscount}
                        />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <CardButtons
                            slimProduct={slimProduct}
                            subscriptionIsOpen={subscriptionIsOpen}
                            subscriptionButtonHandler={subscriptionButtonHandler}
                            buyButtonHandler={buyButtonHandler}
                            oneBuyStatus={oneBuyStatus}
                        />
                        <CardStockStatus stockStatus={slimProduct.stockStatus} />
                    </div>
                </div>
            )}
        </div>

        {/* Popup section */}
        <CardPopup
            showBuyPanel={showBuyPanel}
            subscriptionIsOpen={subscriptionIsOpen}
            slimProduct={slimProduct}
            closeSubscribePopup={closeSubscribePopup}
        />
    </div>
)

export default ColumnCard
