import siteTheme from '@ic-site-theme'
import * as mixins from '@spa-core-js/mixins'
import { merge } from 'ts-deepmerge'
import defaultTheme from './default-theme'
import componentOverrides from '@ic-site-theme-overrides'
import legacyComponentOverrides from '@ic-site-theme-overrides-legacy'

const mergedTheme: any = merge.withOptions({ mergeArrays: false }, defaultTheme, siteTheme)
export default mergedTheme

/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getTheme = (componentName, componentProps = undefined) => {
    const overrides: any = componentOverrides?.[componentName] || legacyComponentOverrides?.[componentName]?.(mergedTheme) || {}
    return merge.withOptions({ mergeArrays: false }, mergedTheme, componentProps || {}, overrides)
}

const themeFactoryGenerator = <ComponentThemeInterface>(
    componentName: string,
    componentProps: ComponentThemeInterface,
): (() => ComponentThemeInterface) => {
    const overrides: any = componentOverrides?.[componentName] || legacyComponentOverrides[componentName]?.(mergedTheme) || {}
    const generatedTheme: any = merge.withOptions({ mergeArrays: false }, siteTheme, componentProps || {}, overrides, mergedTheme)
    mixins.setTheme(generatedTheme)
    return () => generatedTheme
}
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getThemeFactory = <ComponentThemeInterface>(componentName: string, componentProps: ComponentThemeInterface) =>
    themeFactoryGenerator<ComponentThemeInterface>(componentName, componentProps)

export const getComponentTheme = <ComponentThemeInterface>(
    componentName: string,
    componentProps: ComponentThemeInterface = undefined,
) => themeFactoryGenerator<ComponentThemeInterface>(componentName, componentProps)()
