import { ActionTypes } from './constants'
import { FetchProductByCodePayload } from './interfaces'

export const fetchProductByCode = (payload: FetchProductByCodePayload) => ({
    type: ActionTypes.FETCH_PRODUCT_BY_CODE,
    payload,
})

export const fetchProductReviewByCode = (productCode: string) => ({
    type: ActionTypes.FETCH_PRODUCT_REVIEW_BY_CODE,
    payload: {
        productCode,
    },
})

export const fetchProductRelations = (productCode: string, callback: Function) => ({
    type: ActionTypes.FETCH_PRODUCT_RELATIONS,
    payload: {
        productCode,
        callback,
    },
})

export const fetchReplacementProduct = (productCode: string, quantity: number) => ({
    type: ActionTypes.FETCH_REPLACEMENT_PRODUCTS,
    payload: {
        productCode,
        quantity,
    },
})

export const fetchSubscriptionOptions = (productCode: string) => ({
    type: ActionTypes.FETCH_SUBSCRIPTION_OPTIONS,
    payload: {
        productCode,
    },
})

export const setSelectedSubscriptionOptionId = (productCode: string, subscriptionOptionId: string) => ({
    type: ActionTypes.SET_SELECTED_SUBSCRIPTION_OPTION_ID,
    payload: {
        productCode,
        subscriptionOptionId,
    },
})

export const setStockMonitored = (productCode: string, monitored: boolean) => ({
    type: ActionTypes.SET_STOCK_MONITORED,
    payload: {
        productCode,
        monitored,
    },
})

export const registerForStockNotification = (email: string, productCode: string, modelCategoryCode: string) => ({
    type: ActionTypes.REGISTER_FOR_STOCK_NOTIFICATION,
    payload: {
        email,
        productCode,
        modelCategoryCode,
    },
})

export const unregisterForStockNotification = (productCode: string) => ({
    type: ActionTypes.UNREGISTER_FOR_STOCK_NOTIFICATION,
    payload: {
        productCode,
    },
})

export const openEMVBuyComparPopup = () => ({
    type: ActionTypes.OPEN_COMPARE_POPUP,
    payload: {
        popupStatusValue: true,
    },
})
export const closeEMVBuyPopup = () => ({
    type: ActionTypes.CLOSE_COMPARE_POPUP,
    payload: {
        popupStatusValue: false,
    },
})
export const setStockMonitorSubscribeEmail = (email: string) => ({
    type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_EMAIL,
    payload: {
        email,
    },
})

export const setStockMonitorSubscribeError = (error: string) => ({
    type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR,
    payload: {
        error,
    },
})

export const fetchCrossSellProducts = () => ({
    type: ActionTypes.FETCH_CROSS_SELL_PRODUCT_CODES,
})

export const fetchProductsInBatch = (productCodes: string[], slimApi: boolean = true) => ({
    type: ActionTypes.FETCH_PRODUCTS_IN_BATCH,
    payload: {
        productCodes,
        slimApi,
    },
})

export const fetchPromotions = () => ({
    type: ActionTypes.FETCH_PROMOTIONS,
})
export const fetchRepurchaseProductData = () => ({
    type: ActionTypes.FETCH_REPURCHASE_PRODUCT_DATA,
})
