import React, { MouseEventHandler } from 'react'
import { getComponentTheme } from '@ic-theme'
import { Icons } from '@ui-elem/Icon/Icon'
import classNames from 'classnames'
import Button, { ButtonColors, IconAlignments } from '@ui-elem/Button/Button'
import { NAME, QuantityCounterTheme } from './QuantityCounter.theme'

const theme: QuantityCounterTheme = getComponentTheme<QuantityCounterTheme>(NAME, {
    maxQuantity: 100,
})

export const MAX_QUANTITY = theme.maxQuantity
export const MIN_QUANTITY = 1

interface Props {
    onDecrease: MouseEventHandler
    onIncrease: MouseEventHandler
    setCount: Function
    count: number
    readOnly?: boolean
}

const QuantityCounter = ({ onDecrease, onIncrease, setCount, count, readOnly = false }: Props) => (
    <div className={classNames('flex flex-row gap-1 justify-center')}>
        <Button
            className={classNames('p-2 quantityCounterButton')}
            buttonColor={ButtonColors.SECONDARY}
            buttonIcon={Icons.Minus}
            iconAlignment={IconAlignments.CENTER}
            onClick={onDecrease}
        />
        <input
            className={'w-1/5 text-center quantityCounterInput'}
            readOnly={readOnly}
            value={count}
            onChange={(e) => {
                e.preventDefault()
                const newQuantity = parseInt(e.target.value, 10)
                if (typeof newQuantity === 'number' && newQuantity <= MAX_QUANTITY) {
                    setCount(newQuantity)
                } else {
                    setCount(0)
                }
            }}
            onBlur={(e) => {
                e.preventDefault()
                const newQuantity = parseInt(e.target.value, 10)
                if (typeof newQuantity === 'number' && newQuantity === 0) {
                    setCount(1)
                }
            }}
        />

        <Button
            className={classNames('p-2 quantityCounterButton')}
            buttonColor={ButtonColors.SECONDARY}
            buttonIcon={Icons.Plus}
            iconAlignment={IconAlignments.CENTER}
            onClick={onIncrease}
        />
    </div>
)

export default QuantityCounter
