import PropTypes from 'prop-types';
import React from 'react';
import { getThemeFactory } from '@ic-theme';
import Measure from 'react-measure';
import logger from 'score/logSvc';
import Slick from 'react-slick';
import * as cls from './Deck.styles';
import classNames from 'classnames';
import Icon, { Icons } from '@ui-elem/Icon/Icon';

// Todo test nuka-carousel instead of react slick

const NextArrow = ({ onClick = () => {} }) => (
  <Icon
    icon={Icons.AngleRight}
    size={38}
    onClick={onClick}
    className={classNames('pr-2 cursor-pointer z-10 absolute right-0 top-1/2 e2e-ver-next-icon')}
  />
);

const PrevArrow = ({ onClick = () => {} }) => (
  <Icon
    icon={Icons.AngleLeft}
    size={38}
    onClick={onClick}
    className={classNames('cursor-pointer z-10 absolute left-0 top-1/2 mr-4 e2e-ver-prev-icon')}
  />
);

const th = getThemeFactory('Deck', {});
const log = logger.getLogger('Deck');

const getCardStyles = (width, margin, first, last, space) => {
  const styles = {
    width: width ? width + 'px' : '100%',
  };
  if (margin) {
    styles.marginLeft = `${margin}px`;
    styles.marginRight = `${margin}px`;
    styles.marginBottom = `${margin}px`;
    styles.marginTop = `${margin}px`;
  } else {
    styles.marginBottom = `${space}rem`;
  }
  if (first) styles.marginLeft = 0;
  if (last) styles.marginRight = 0;
  return styles;
};
class FixedDeck extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      /** The alignment of cards in the last row that is not full */
      children: PropTypes.node,
      min: PropTypes.number,
      mobileMinWidth: PropTypes.number,
      oneRow: PropTypes.bool,
      noGutter: PropTypes.bool,
      halfGutter: PropTypes.bool,
      /** The alignment of cards in the last row that is not full */
      align: PropTypes.string,
      autoPlay: PropTypes.bool,
      autoPlaySpeed: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: 500,
        height: 500,
      },
    };
  }
  render() {
    const { width } = this.state.dimensions;
    const theme = th();
    let gutter = Math.round(theme.space * 16);
    let halfGutter = Math.round(theme.space * 8);
    if (this.props.halfGutter) {
      gutter = Math.round(theme.space * 8);
      halfGutter = Math.round(theme.space * 4);
    }
    if (this.props.noGutter) {
      gutter = 0;
      halfGutter = 0;
    }

    let minWidth = this.props.min;
    if (this.props.mobileMinWidth && window.innerWidth < 580) {
      minWidth = this.props.mobileMinWidth;
    }
    if (this.props.oneRow) {
      const settings = {
        dots: true,
        arrows: true,
        fade: false,
        infinite: true,
        autoplay: this.props.autoPlay ? this.props.autoPlay : false,
        autoplaySpeed:
          this.props.autoPlaySpeed && this.props.autoPlaySpeed.trim() !== '' ? parseInt(this.props.autoPlaySpeed, 10) : 10000,
        swipeToSlide: true,
        variableWidth: false,
        lazyLoad: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
      return (
        <Measure
          bounds={true}
          onResize={(contentRect) => {
            this.setState({ dimensions: contentRect.bounds });
          }}
        >
          {({ measureRef }) => {
            // Adjust for negative margins
            if (width < 450) {
              settings.arrows = false;
              settings.dots = true;
            }
            const adjWidth = width - gutter;
            let num = Math.floor(adjWidth / (minWidth + halfGutter));
            if (num === 0) {
              num = 1;
            }

            const _num = Math.floor((width - minWidth) / (minWidth + gutter) + 1); //  / (1 + this.props.min / gutter);
            let _w = Math.floor((width - (_num - 1) * gutter) / _num);
            if (theme.conf.paddingInContentBlock) {
              _w -= halfGutter / 2;
            }
            settings.slidesToShow = _num;

            const children = this.props.children;
            const itemWidth = Math.round((width - gutter * (num - 1)) / num);

            if (children.length <= num) {
              return (
                <div
                  ref={measureRef}
                  width={width + 2 * gutter}
                  num={_num}
                  className={classNames(
                    'flex flex-wrap justify-start p-0 w-full e2e-ver-deck gap-1',
                    cls['flex-deck'],
                    cls['non-carousel-child-cards'],
                    cls[this.props.align]
                  )}
                >
                  {React.Children.map(children, (child, i) =>
                    React.cloneElement(child, {
                      width: _w,
                      margin: halfGutter,
                      last: (i % _num === _num - 1).toString(),
                      first: (i % _num === 0).toString(),
                    })
                  )}
                </div>
              );
            }

            return (
              <div className={cls['one-row-deck'] + ' e2e-ver-deck-upsell'} ref={measureRef}>
                <Slick style={{ width: `${itemWidth * num + gutter * num}px` }} {...settings} negativeMargin={halfGutter}>
                  {React.Children.map(this.props.children, (child) => (
                    <div>
                      {React.cloneElement(child, {
                        margin: halfGutter,
                        width: _w,
                      })}
                    </div>
                  ))}
                </Slick>
              </div>
            );
          }}
        </Measure>
      );
    }

    return (
      <Measure
        bounds={true}
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => {
          // Adjust for negative margins
          let num = Math.floor(width / (minWidth + halfGutter));
          if (num === 0) {
            num = 1;
          }
          const _num = Math.floor((width - minWidth) / (minWidth + gutter) + 1); //  / (1 + this.props.min / gutter);
          const _w = Math.floor((width - (_num - 1) * gutter) / _num);

          return (
            <div
              ref={measureRef}
              className={classNames(
                'flex flex-wrap justify-start p-0 w-full e2e-ver-deck-questioner-result',
                cls['flex-deck'],
                cls[this.props.align]
              )}
            >
              {React.Children.map(this.props.children, (child, i) =>
                React.cloneElement(child, {
                  width: _w,
                  margin: halfGutter,
                  last: (i % _num === _num - 1 || i === this.props.children.length - 1).toString(),
                  first: (i % _num === 0).toString(),
                  style: getCardStyles(
                    _w,
                    halfGutter,
                    i % _num === 0,
                    i % _num === _num - 1 || i === this.props.children.length - 1
                  ),
                })
              )}
            </div>
          );
        }}
      </Measure>
    );
  }
}
FixedDeck.defaultProps = {
  min: 250,
  oneRow: false,
  align: 'center',
  noGutter: false,
};

export default FixedDeck;
