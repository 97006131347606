import React from 'react'
import loadable from '@loadable/component'
import { SlimProduct } from '@spa-core/store/products/interfaces'

const Popup = loadable(() => import('../Popup/Popup'))

type Props = {
    subscriptionIsOpen: boolean
    slimProduct: SlimProduct
    closeSubscribePopup: Function
    showBuyPanel: boolean
}

const CardPopup: React.FC<Props> = ({ subscriptionIsOpen, slimProduct, closeSubscribePopup, showBuyPanel }) => (
    <>
        {/* Popup section */}
        {subscriptionIsOpen ? (
            <Popup closeSubscribePopup={closeSubscribePopup} showBuyPanel={showBuyPanel} basicProduct={slimProduct} />
        ) : null}
    </>
)

export default CardPopup
